import React from 'react';
import PropTypes from 'prop-types';
import './Fret.css';

const Fret = ({ note, playSound, showNotes, isOpenNote, isRootNote, isPlaying, additionalClass, onMouseEnter }) => {
  const handleMouseOver = () => {
    if (showNotes && typeof playSound === 'function' && note) {
      try {
        playSound(note);
        if (onMouseEnter) {
          onMouseEnter(note); // Trigger the hover handler if provided
        }
      } catch (error) {
        console.error(`Failed to play note: ${note}`, error);
      }
    }
  };

  return (
    <div
      className={`fret ${isOpenNote ? 'open-note' : 'fretted-note'} ${showNotes ? 'show-notes' : ''} ${isRootNote ? 'root-note' : ''} ${isPlaying ? 'playing-note' : ''} ${additionalClass || ''}`}
      onMouseOver={handleMouseOver}
    >
      {note && <div className="fret-note">{note}</div>}
    </div>
  );
};

Fret.propTypes = {
  note: PropTypes.string.isRequired,
  playSound: PropTypes.func,
  showNotes: PropTypes.bool,
  isOpenNote: PropTypes.bool,
  isRootNote: PropTypes.bool,
  isPlaying: PropTypes.bool,
  additionalClass: PropTypes.string,
  onMouseEnter: PropTypes.func, // Added prop type validation for onMouseEnter
};

export default React.memo(Fret);
