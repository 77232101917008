import React from 'react';
import PropTypes from 'prop-types';
import './Metronome.css';

const Metronome = ({ bpm, setBpm, closeMetronome }) => {
  
  // Handle BPM change with validation
  const handleBpmChange = (e) => {
    const value = e && e.target && e.target.value ? Number(e.target.value) : null;

    if (value === null) {
      console.error("BPM change event is not defined correctly.");
      return;
    }

    // Ensure the value is within the allowed range
    if (value < 20) {
      alert("BPM cannot be less than 20.");
      setBpm(20); // Set to minimum if below range
    } else if (value > 2000) {
      alert("BPM cannot be greater than 2000.");
      setBpm(2000); // Set to maximum if above range
    } else {
      setBpm(value);
    }
  };

  return (<div className="metronome-overlay">
    <div className="metronome-popup">
      <div className="metronome-header">
        <h2>Metronome</h2>
      </div>
      <div className="metronome-body">
        <label htmlFor="bpm">BPM:</label>
        <input
          id="bpm"
          type="number"
          value={bpm || ''}  // Ensure bpm is defined and fallback to an empty string if it's not
          onChange={handleBpmChange}
          min="20"
          max="2000"
        />
      </div>
      <button onClick={closeMetronome}>Close</button>
    </div></div>
  );
};

Metronome.propTypes = {
  bpm: PropTypes.number.isRequired,
  setBpm: PropTypes.func.isRequired,
  closeMetronome: PropTypes.func.isRequired,
};

export default Metronome;
