import React from 'react';
import './FretNumbers.css';

const FretNumbers = ({ fretNumbers, showFretNumbers }) => {
  return (
    <div className="fret-numbers">
      {fretNumbers.map((number, index) => (
        <div key={index} className={`fret-number ${number === 0 ? 'open' : showFretNumbers ? '' : 'hidden'}`}>
          {number === 0 ? 'Open' : number}
        </div>
      ))}
    </div>
  );
};

export default React.memo(FretNumbers);
