import { useEffect, useRef, useState, useCallback } from 'react';
import Soundfont from 'soundfont-player';

const useAudioPlayer = (sound) => {
  const audioContext = useRef(null);
  const [player, setPlayer] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const initializeAudioContext = useCallback(() => {
    if (!audioContext.current) {
      audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
      console.log("AudioContext created.");
    }
    if (audioContext.current.state === 'suspended') {
      console.log("Resuming AudioContext after user interaction.");
      audioContext.current.resume().then(() => {
        console.log("AudioContext resumed successfully.");
      }).catch(err => {
        console.error("Error resuming AudioContext:", err);
      });
    }
  }, []);

  const initializePlayer = useCallback(() => {
    if (audioContext.current && audioContext.current.state === 'running') {
      console.log("Attempting to initialize player with sound:", sound);
      Soundfont.instrument(audioContext.current, sound).then((instrument) => {
        setPlayer(instrument);
        setInitialized(true);
        console.log("Soundfont player initialized for:", sound);
      }).catch(error => {
        console.error("Error loading instrument:", error);
      });
    } else {
      console.log("Cannot initialize player because AudioContext is not running.");
    }
  }, [sound]);

  useEffect(() => {
    if (audioContext.current && sound) {
      setPlayer(null); // Clear current player
      setInitialized(false); // Reset initialization state
      initializePlayer(); // Force reinitialization
    }
  }, [sound, initializePlayer]);

  const playSound = (note) => {
    if (!audioContext.current || audioContext.current.state !== 'running') {
      console.warn("AudioContext is not started. Please interact with the page to start AudioContext.");
      return;
    }

    if (player) {
      player.play(note);
    } else {
      console.warn("Soundfont player is not initialized. Initializing now...");
      initializePlayer();
      // Optionally, retry playing the note after initializing the player
      setTimeout(() => {
        if (player) player.play(note);
      }, 500); // Delay to allow for initialization
    }
  };

  const stopSound = () => {
    if (player) {
      player.stop();
      console.log('Sound stopped');
    } else {
      console.warn("Soundfont player is not initialized.");
    }
  };

  const handleUserGesture = useCallback(() => {
    initializeAudioContext();
    initializePlayer();
  }, [initializeAudioContext, initializePlayer]);

  useEffect(() => {
    if (!initialized) {
      document.addEventListener('click', handleUserGesture, { once: true });
      document.addEventListener('keydown', handleUserGesture, { once: true });
    }

    return () => {
      document.removeEventListener('click', handleUserGesture);
      document.removeEventListener('keydown', handleUserGesture);
    };
  }, [handleUserGesture, initialized]);

  return {
    playSound,
    stopSound,
    handleUserGesture, // Exposed for manual triggering if needed
  };
};

export default useAudioPlayer;
