export const tunings = {
  Standard: ['E2', 'A2', 'D3', 'G3', 'B3', 'E4'],
  StandardC: ['C2', 'F2', 'A#2', 'D#3', 'G3', 'C4'],
  StandardD: ['D2', 'G2', 'C3', 'F3', 'A3', 'D4'],
  DropD: ['D2', 'A2', 'D3', 'G3', 'B3', 'E4'],
  DropC: ['C2', 'G2', 'C3', 'F3', 'A3', 'D4'],
  DropB: ['B1', 'F#2', 'B2', 'E3', 'G#3', 'C#4'],
  DropA: ['A1', 'E2', 'A2', 'D#3', 'F#3', 'B3'],
  DoubleDropD: ['D2', 'A2', 'D3', 'G3', 'B3', 'D4'],
  OpenG: ['D2', 'G2', 'D3', 'G3', 'B3', 'D4'],
  OpenD: ['D2', 'A2', 'D3', 'F#3', 'A3', 'D4'],
  OpenE: ['E2', 'B2', 'E3', 'G#3', 'B3', 'E4'],
  OpenC: ['C2', 'G2', 'C3', 'G3', 'C4', 'E4'],
  OpenA: ['E2', 'A2', 'C#3', 'E3', 'A3', 'E4'],
  ModalC6: ['C2', 'A2', 'C3', 'G3', 'C4', 'E4'],
  DADGAD: ['D2', 'A2', 'D3', 'G3', 'A3', 'D4']
};

export const tuningDisplayNames = {
  Standard: 'Standard',
  StandardC: 'Standard C',
  StandardD: 'Standard D',
  DropD: 'Drop D',
  DropC: 'Drop C',
  DropB: 'Drop B',
  DropA: 'Drop A',
  DoubleDropD: 'Double Drop D',
  OpenG: 'Open G',
  OpenD: 'Open D',
  OpenE: 'Open E',
  OpenC: 'Open C',
  OpenA: 'Open A',
  ModalC6: 'Modal C6',
  DADGAD: 'DADGAD'
};

export const scales = [
  'Choose a Scale',
  'Major',
  'Minor',
  'Pentatonic Major',
  'Pentatonic Minor',
  'Blues',
  'Chromatic',
  'Whole Tone',
  'Harmonic Minor',
  'Melodic Minor',
  'Natural Minor',
  'Jazz Minor'
];

export const commonKeys = [
  'C', 'G', 'D', 'A', 'E', 'B', 'F#', 'Db', 'Ab', 'Eb', 'Bb', 'F'
];

export const pentatonicKeys = [
  'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'
];

export const octaves = [
  '1st Octave', '2nd Octave', '3rd Octave', '4th Octave'
];

export const scalePositions = {
  'Pentatonic Minor': 5,
  'Pentatonic Major': 5,
  'Major': 7,
  'Minor': 7,
  'Harmonic Minor': 7,
  'Melodic Minor': 7,
  'Dorian': 7,
  'Phrygian': 7,
  'Lydian': 7,
  'Mixolydian': 7,
  'Aeolian': 7,
  'Locrian': 7,
  'Blues': 6,
  'Chromatic': 1,
  'Whole Tone': 1,
  'Natural Minor': 7,
  'Jazz Minor': 7,
};

export const scaleModes = [
  'Ionian', 'Dorian', 'Phrygian', 'Lydian', 'Mixolydian', 'Aeolian', 'Locrian'
];

export const ranges = [
  'Lower Octave', 'Middle Octave', 'Upper Octave'
];

export const soundDisplayNames = {
  acoustic_guitar_nylon: 'Acoustic Guitar Nylon',
  acoustic_guitar_steel: 'Acoustic Guitar Steel',
  electric_guitar_clean: 'Electric Guitar Clean',
  electric_guitar_jazz: 'Electric Guitar Jazz',
  electric_guitar_muted: 'Electric Guitar Muted',
  overdriven_guitar: 'Overdriven Guitar',
  distorted_guitar: 'Distorted Guitar',
  guitar_harmonics: 'Guitar Harmonics',
  nylon_acoustic_guitar: 'Nylon Acoustic Guitar',
  steel_acoustic_guitar: 'Steel Acoustic Guitar',
  clean_electric_guitar: 'Clean Electric Guitar',
  jazz_electric_guitar: 'Jazz Electric Guitar',
  muted_electric_guitar: 'Muted Electric Guitar',
  overdriven_electric_guitar: 'Overdriven Electric Guitar',
  distorted_electric_guitar: 'Distorted Electric Guitar',
  harmonics_guitar: 'Harmonics Guitar'
};
