import React, { useState, useCallback, useMemo, lazy, useEffect, Suspense } from 'react';
import Fret from './Fret';
import FretNumbers from './FretNumbers';
import Metronome from './Metronome';
import PlayControls from './PlayControls';
import { scales, tunings, tuningDisplayNames, soundDisplayNames } from '../utils/constants';
import { getPositionNotes } from '../utils/scaleUtils';
import useScaleManagement from '../hooks/useScaleManagement';
import useAudioControl from '../hooks/useAudioControl';
import styles from './Fretboard.css';

const SettingsPopup = lazy(() => import('./SettingsPopup'));
const ScalesPopup = lazy(() => import('./ScalesPopup'));

const DEFAULT_TUNING_KEY = 'Standard';
const DEFAULT_TUNING = tunings[DEFAULT_TUNING_KEY];

const Fretboard = ({
  numFrets = 24,
  selectedSound,
  showNotes = true,
  showFretNumbers,
  reverseStrings,
  tuning = DEFAULT_TUNING,
  setTuning,
  showScalesPopup,
  toggleScalesPopup,
  showSettingsPopup,
  toggleSettingsPopup,
}) => {
  const [currentTuningKey, setCurrentTuningKey] = useState(DEFAULT_TUNING_KEY);
  const [selectedSoundState, setSelectedSoundState] = useState(selectedSound);
  const [tuningState, setTuningState] = useState(tuning);
  const [numFretsState, setNumFretsState] = useState(numFrets);
  const [showNotesState, setShowNotesState] = useState(showNotes);
  const [showFretNumbersState, setShowFretNumbersState] = useState(showFretNumbers);
  const [reverseStringsState, setReverseStringsState] = useState(reverseStrings);
  const [showAll, setShowAll] = useState(false);
  const [showMetronome, setShowMetronome] = useState(false);
  const [bpm, setBpm] = useState(null); // Start with no default BPM
  const [playingNotes, setPlayingNotes] = useState([]); // Track all played notes

  const {
    selectedScale,
    selectedKey,
    selectedPosition,
    selectedMode,
    handleScaleChange,
    handleKeyChange,
    handlePositionChange,
    handleModeChange,
    getSelectableKeys,
    getSelectablePositions,
  } = useScaleManagement();

  const { notes: positionNotes, range: positionRange = { start: 0, end: numFretsState - 1 } } = useMemo(() =>
    selectedScale === 'Choose a Scale' || selectedScale === 'No Scale'
      ? { notes: [], range: { start: 0, end: numFretsState - 1 } }
      : getPositionNotes(selectedScale, selectedKey, selectedPosition === "Show All" ? "Show All" : selectedPosition, numFretsState),
    [selectedScale, selectedKey, selectedPosition, numFretsState]
  );

  const handleShowAllChange = (checked) => {
    setShowAll(checked);
  };

  const getNoteName = useCallback(
    (stringIndex, fret) => {
      const noteNames = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
      const openStringNote = tuningState[stringIndex];
      const noteIndex =
        (noteNames.indexOf(openStringNote.slice(0, -1)) + fret) % noteNames.length;
      const octave =
        parseInt(openStringNote.slice(-1), 10) +
        Math.floor((noteNames.indexOf(openStringNote.slice(0, -1)) + fret) / noteNames.length);
      return noteNames[noteIndex] + octave;
    },
    [tuningState]
  );

  const fretNotes = useMemo(() => {
    const notes = tuningState.map((openNote, stringIndex) => {
      return new Array(numFretsState + 1)
        .fill(null)
        .map((_, fret) => getNoteName(stringIndex, fret));
    });
    return reverseStringsState ? notes.reverse() : notes;
  }, [tuningState, numFretsState, reverseStringsState, getNoteName]);

  const {
    isPlaying, loop, handlePlayPause, setLoop, playSound, currentNoteIndex, stepForward, stepBackward, notesToPlay
  } = useAudioControl(selectedSoundState, fretNotes, bpm, selectedScale, selectedPosition, positionNotes);

  const fretNumbers = useMemo(
    () => Array.from({ length: numFretsState + 1 }, (_, i) => i),
    [numFretsState]
  );

  const markerFrets = useMemo(
    () => fretNumbers.filter(fret => [3, 5, 7, 9, 12, 15, 17, 19, 21, 24].includes(fret)),
    [fretNumbers]
  );

  const handleMetronome = useCallback(() => setShowMetronome(!showMetronome), [showMetronome]);

  const closeMetronome = useCallback(() => setShowMetronome(false), []);
  const handleSettings = useCallback(() => toggleSettingsPopup(), [toggleSettingsPopup]);
  const closeSettings = useCallback(() => toggleSettingsPopup(), [toggleSettingsPopup]);

  const getSoundDisplayName = useCallback((sound) => soundDisplayNames[sound] || sound, []);

  const rootNotes = selectedScale !== 'No Scale' && selectedScale !== 'Choose a Scale' ? [selectedKey] : [];
  const tuningName = tuningDisplayNames[currentTuningKey] || currentTuningKey;

  useEffect(() => {
    setBpm(60);  // Explicitly set the BPM to 60 on initial render
  }, [setBpm]);

  useEffect(() => {
    if (currentNoteIndex >= 0 && notesToPlay.length > 0) {
      setPlayingNotes(prevNotes => [...prevNotes, notesToPlay[currentNoteIndex]]);
    }
  }, [currentNoteIndex, notesToPlay]);

  const handlePlay = () => {
    if (bpm) {
      handlePlayPause();  // Start playing if BPM is set
    } else {
      console.warn('Cannot play, BPM is not set.');
    }
  };

  const handleNoteHover = useCallback((note) => {
    setPlayingNotes(prevNotes => [...prevNotes, note]);
  }, []);

  const handlePlayNote = (note) => {
    playSound(note);
    setPlayingNotes(prevNotes => [...prevNotes, note]);
  };

  const handleSoundChange = (newSound) => {
    setSelectedSoundState(newSound);
  };

  return (
    <div className={`${styles.fretboardContainer} ${reverseStringsState ? 'reverse-strings' : ''}`}>
      <div className={styles.fretboardWrapper}>
        <div className="fretboard">
          {fretNotes.map((string, stringIndex) => (
            <div key={stringIndex} className="string">
              {string.map((note, fretIndex) => {
                const noteName = note.slice(0, -1);
                const noteIndex = stringIndex * (numFretsState + 1) + fretIndex;
                const showNote = selectedScale === 'No Scale' || selectedScale === 'Choose a Scale' || !positionNotes.length
                  ? showNotesState
                  : (positionNotes.includes(note) && fretIndex >= positionRange.start && fretIndex <= positionRange.end);

                const noteBackgroundClass = selectedScale === 'No Scale' || selectedScale === 'Choose a Scale' ? styles.fretNoteBackgroundGrey : '';
                return (
                  <Fret
                    key={fretIndex}
                    note={note}
                    playSound={playSound}
                    showNotes={showNote}
                    isOpenNote={fretIndex === 0}
                    isRootNote={rootNotes.includes(noteName)}
                    isPlaying={currentNoteIndex === noteIndex}
                    additionalClass={noteBackgroundClass}
                    onMouseEnter={() => handleNoteHover(note)} // Capture hover event
                  />
                );
              })}
            </div>
          ))}
          {markerFrets.map((fretIndex) => (
            <div
              key={fretIndex}
              className={`fret-marker ${fretIndex === 12 || fretIndex === 24 ? 'double' : ''}`}
              style={{ left: `${(fretIndex / (numFretsState + 1)) * 100}%` }}
            >
              <div className="dot"></div>
              {(fretIndex === 12 || fretIndex === 24) && <div className="dot"></div>}
            </div>
          ))}
        </div>
        <FretNumbers fretNumbers={fretNumbers} showFretNumbers={showFretNumbersState} />
      </div>
      <PlayControls
        isPlaying={isPlaying}
        loop={loop}
        handleStepBackward={stepBackward}
        handlePlayPause={handlePlayPause}
        handleLoop={setLoop}
        handleStepForward={stepForward}
        handleMetronome={handleMetronome}
        handleSettings={handleSettings}
        showMetronome={showMetronome}
        toggleScalesPopup={toggleScalesPopup}
        showScalesPopup={showScalesPopup}
        selectedScale={selectedScale}
        selectedKey={selectedKey}
        selectedPosition={selectedPosition}
        selectedMode={selectedMode}
        handleScaleChange={handleScaleChange}
        handleKeyChange={handleKeyChange}
        handlePositionChange={handlePositionChange}
        handleModeChange={handleModeChange}
        getSelectableKeys={getSelectableKeys}
        getSelectablePositions={getSelectablePositions}
        scales={scales}
        scalesWithoutPositions={['Chromatic', 'Whole Tone', 'Blues']}
        modes={['Ionian', 'Dorian', 'Phrygian', 'Lydian', 'Mixolydian', 'Aeolian', 'Locrian']}
        tuning={tuningState.join(',')}
        sound={getSoundDisplayName(selectedSoundState)}
        numFrets={numFretsState}
        currentTuningKey={tuningName}
        bpm={bpm}
        handleBpmChange={setBpm} // Pass the BPM handler
        handleSoundChange={handleSoundChange} // Pass the sound change handler
        playnowNotes={playingNotes} // Pass the notes to PlayControls
        handlePlayNote={handlePlayNote} // Pass the play note handler
      />
      {showMetronome && <Metronome bpm={bpm} setBpm={setBpm} closeMetronome={closeMetronome} />}
      <Suspense fallback={<div>Loading...</div>}>
        {showSettingsPopup && (
          <SettingsPopup
            isOpen={showSettingsPopup}
            onClose={closeSettings}
            tuningState={tuningState}
            setTuning={(newTuning) => {
              setTuningState(newTuning);
              const tuningKey = Object.keys(tuningDisplayNames).find(key => tunings[key].toString() === newTuning.toString());
              setCurrentTuningKey(tuningKey || DEFAULT_TUNING_KEY);
            }}
            numFretsState={numFretsState}
            setNumFrets={setNumFretsState}
            selectedSoundState={selectedSoundState}
            setSelectedSound={setSelectedSoundState}
            showNotesState={showNotesState}
            setShowNotes={setShowNotesState}
            showFretNumbersState={showFretNumbersState}
            setShowFretNumbers={setShowFretNumbersState}
            reverseStringsState={reverseStringsState}
            setReverseStrings={setReverseStringsState}
          />
        )}
        {showScalesPopup && (
          <ScalesPopup
            show={showScalesPopup}
            handleClose={toggleScalesPopup}
            selectedScale={selectedScale}
            selectedKey={selectedKey}
            selectedPosition={selectedPosition}
            selectedMode={selectedMode}
            handleScaleChange={handleScaleChange}
            handleKeyChange={handleKeyChange}
            handlePositionChange={handlePositionChange}
            handleModeChange={handleModeChange}
            getSelectableKeys={getSelectableKeys}
            getSelectablePositions={getSelectablePositions}
            scales={scales}
            scalesWithoutPositions={['Chromatic', 'Whole Tone', 'Blues']}
            modes={['Ionian', 'Dorian', 'Phrygian', 'Lydian', 'Mixolydian', 'Aeolian', 'Locrian']}
            showAll={showAll}
            handleShowAllChange={handleShowAllChange}
          />
        )}
      </Suspense>
    </div>
  );
};

export default Fretboard;
