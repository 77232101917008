import React, { useEffect, memo, useState } from 'react';
import PropTypes from 'prop-types';
import backIcon from './icons/back.svg';
import loopIcon from './icons/loop.svg';
import playIcon from './icons/play.svg';
import pauseIcon from './icons/pause.svg';
import metronomeIcon from './icons/metronome.svg';
import stepIcon from './icons/step.svg';
import './PlayControls.css';

const defaultBpmValues = [30, 60, 80, 100, 120, 180];

const PlayControls = ({
  isPlaying,
  loop,
  bpm,
  handleStepBackward,
  handlePlayPause,
  handleLoop,
  handleStepForward,
  handleMetronome,
  handleSettings,
  toggleScalesPopup,
  selectedScale,
  selectedKey,
  selectedPosition,
  selectedMode,
  handleScaleChange,
  handleKeyChange,
  handlePositionChange,
  handleModeChange,
  getSelectableKeys,
  getSelectablePositions,
  scales,
  scalesWithoutPositions,
  modes,
  tuning,
  sound,
  numFrets,
  currentTuningKey,
  handleBpmChange,
  playnowNotes,
  handlePlayNote,
}) => {
  const [selectedNote, setSelectedNote] = useState('C');
  const selectablePositions = getSelectablePositions();
  const hasModes = selectedScale === 'Major';
  const showPositions = !scalesWithoutPositions.includes(selectedScale);

  useEffect(() => {
    console.log("PlayControls render with selected sound:", sound);
  }, [sound]);

  const handleTouchEvent = (event, handler) => {
    event.preventDefault();
    event.stopPropagation();
    handler();
  };

  const handlePositionClick = (position) => {
    handlePositionChange({ target: { value: position } });
  };

  const handleNoteClick = (note) => {
    setSelectedNote(note);
    handlePlayNote(note);
  };

  const handleBpmClick = (bpm) => {
    handleBpmChange(bpm);
  };

  // Slice the playnowNotes array to show only the last 10 notes
  const latestNotes = [...playnowNotes].slice(-10).reverse();

  return (
    <div className="playcontrols">
      <div className="upper-controls">
        <div
          className="settings-display"
          onMouseDown={handleSettings}
          onTouchStart={(e) => handleTouchEvent(e, handleSettings)}
        >
          <div>Tuning: {currentTuningKey}</div>
          <div>Strings: {tuning}</div>
          <div>Sound: {sound} Frets: {numFrets}</div>
        </div>
        <div className="scale-display">
          <div
            className="scale-info"
            onMouseDown={toggleScalesPopup}
            onTouchStart={(e) => handleTouchEvent(e, toggleScalesPopup)}
          >
            <div>{selectedScale === 'No Scale' || selectedScale === 'Choose a Scale' ? 'Choose a Scale' : `Scale: ${selectedScale}`}</div>
            {selectedScale !== 'No Scale' && selectedScale !== 'Choose a Scale' && (
              <>
                {hasModes && <div>Mode: {selectedMode || 'Ionian'} Key: {selectedKey}</div>}
                {!hasModes && <div>Key: {selectedKey}</div>}
              </>
            )}
          </div>
          <div className="scale-positions">
            {selectedScale !== 'No Scale' && selectedScale !== 'Choose a Scale' && (
              <>
                {showPositions && selectablePositions.map((position, index) => (
                  <div
                    key={index}
                    className={`position ${position === selectedPosition ? 'active' : ''} ${position === 'Show All' ? 'show-all' : ''}`}
                    onMouseDown={() => handlePositionClick(position)}
                    onTouchStart={(e) => handleTouchEvent(e, () => handlePositionClick(position))}
                  >
                    {position.replace('Position ', '')}
                  </div>
                ))}
                {!showPositions && (
                  <div
                    className={`position ${selectedPosition === 'Show All' ? 'active' : ''} show-all`}
                    onMouseDown={() => handlePositionClick('Show All')}
                    onTouchStart={(e) => handleTouchEvent(e, () => handlePositionClick('Show All'))}
                  >
                    Show All
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div
          className="scale-info metronome-display"
          onMouseDown={handleMetronome}  // Clickable BPM label
          onTouchStart={(e) => handleTouchEvent(e, handleMetronome)}  // Touch support for mobile devices
          style={{ cursor: 'pointer' }}   // Indicate that the BPM label is clickable
        >
          <div>BPM: {bpm}</div>
          <div className="bpm-buttons" style={{ display: 'flex', gap: '5px', marginTop: '5px' }}>
            {defaultBpmValues.map((bpmValue) => (
              <div
                key={bpmValue}
                className={`position ${bpm === bpmValue ? 'active' : ''}`}
                onMouseDown={() => handleBpmClick(bpmValue)}
                onTouchStart={(e) => handleTouchEvent(e, () => handleBpmClick(bpmValue))}
                style={{ cursor: 'pointer' }}
              >
                {bpmValue}
              </div>
            ))}
          </div>
        </div>
        <div className="scale-info playnote-display">
          <div>Sound Player: Currently Playing: {selectedNote}</div>
          <div className="note-buttons" style={{ display: 'flex', gap: '5px', marginTop: '5px' }}>
            {['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'].map((note) => (
              <div
                key={note}
                className={`position ${note === selectedNote ? 'active' : ''}`}
                onMouseDown={() => handleNoteClick(note)}
                onTouchStart={(e) => handleTouchEvent(e, () => handleNoteClick(note))}
                style={{ cursor: 'pointer' }}
              >
                {note}
              </div>
            ))}
          </div>
          
        </div>
        <div
          className="scale-info playnow-display"
        >
          <div>Notes Playing Now:</div>
          <div className="playnow-notes">
            {latestNotes.map((note, index) => (
              <span
                key={index}
                className={`note ${index === 0 ? 'recent-note' : ''}`}
              >
                {note}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="lower-controls">
        <button onClick={handleStepBackward}>
          <img src={backIcon} alt="Back" />
          <span>Back</span>
        </button>
        <button className={isPlaying ? 'active' : ''} onClick={handlePlayPause}>
          <img src={isPlaying ? pauseIcon : playIcon} alt={isPlaying ? 'Pause' : 'Play'} />
          <span>{isPlaying ? 'Pause' : 'Play'}</span>
        </button>
        <button className={loop ? 'active' : ''} onClick={() => handleLoop(!loop)}>
          <img src={loopIcon} alt="Loop" />
          <span>Loop</span>
        </button>
        <button onClick={handleStepForward}>
          <img src={stepIcon} alt="Step" />
          <span>Step</span>
        </button>
     
      </div>
    </div>
  );
};

PlayControls.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  loop: PropTypes.bool.isRequired,
  bpm: PropTypes.number.isRequired,
  handleStepBackward: PropTypes.func.isRequired,
  handlePlayPause: PropTypes.func.isRequired,
  handleLoop: PropTypes.func.isRequired,
  handleStepForward: PropTypes.func.isRequired,
  handleMetronome: PropTypes.func.isRequired,
  handleSettings: PropTypes.func.isRequired,
  toggleScalesPopup: PropTypes.func.isRequired,
  selectedScale: PropTypes.string.isRequired,
  selectedKey: PropTypes.string.isRequired,
  selectedPosition: PropTypes.string.isRequired,
  selectedMode: PropTypes.string.isRequired,
  handleScaleChange: PropTypes.func.isRequired,
  handleKeyChange: PropTypes.func.isRequired,
  handlePositionChange: PropTypes.func.isRequired,
  handleModeChange: PropTypes.func.isRequired,
  getSelectableKeys: PropTypes.func.isRequired,
  getSelectablePositions: PropTypes.func.isRequired,
  scales: PropTypes.array.isRequired,
  scalesWithoutPositions: PropTypes.array.isRequired,
  modes: PropTypes.array.isRequired,
  tuning: PropTypes.string.isRequired,
  sound: PropTypes.string.isRequired,
  numFrets: PropTypes.number.isRequired,
  currentTuningKey: PropTypes.string.isRequired,
  handleBpmChange: PropTypes.func.isRequired,
  playnowNotes: PropTypes.array.isRequired,
  handlePlayNote: PropTypes.func.isRequired,
};

export default memo(PlayControls);
