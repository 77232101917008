import { useState } from 'react';
import { scales, commonKeys, pentatonicKeys, scalePositions } from '../utils/constants';

const useScaleManagement = () => {
  const [selectedScale, setSelectedScale] = useState('No Scale');
  const [selectedKey, setSelectedKey] = useState('C');
  const [selectedPosition, setSelectedPosition] = useState('Show All');
  const [selectedMode, setSelectedMode] = useState('');

  const handleScaleChange = (event) => {
    setSelectedScale(event.target.value);
    setSelectedPosition('Show All');
    setSelectedMode('');
    console.log(`Scale changed to: ${event.target.value}`);
  };

  const handleKeyChange = (event) => {
    setSelectedKey(event.target.value);
    console.log(`Key changed to: ${event.target.value}`);
  };

  const handlePositionChange = (event) => {
    setSelectedPosition(event.target.value);
    console.log(`Position changed to: ${event.target.value}`);
  };

  const handleModeChange = (event) => {
    setSelectedMode(event.target.value);
  };

  const getSelectableKeys = () => {
    if (selectedScale === 'Pentatonic Minor') return pentatonicKeys;
    return commonKeys.concat(pentatonicKeys.filter(key => !commonKeys.includes(key)));
  };

  const getSelectablePositions = () => {
    const positionsCount = scalePositions[selectedScale] || 0;
    return ['Show All', ...Array.from({ length: positionsCount }, (_, i) => `Position ${i + 1}`)];
  };

  // Example use of unused variables to prevent warnings
  console.log(scales);

  return {
    selectedScale,
    selectedKey,
    selectedPosition,
    selectedMode,
    handleScaleChange,
    handleKeyChange,
    handlePositionChange,
    handleModeChange,
    getSelectableKeys,
    getSelectablePositions,
  };
};

export default useScaleManagement;
