import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import useAudioPlayer from './useAudioPlayer';

const useAudioControl = (selectedSound, fretNotes, bpm, selectedScale, selectedPosition, positionNotes) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentNoteIndex, setCurrentNoteIndex] = useState(-1);
  const [loop, setLoop] = useState(false);
  const timerRef = useRef(null);

  const { playSound } = useAudioPlayer(selectedSound);

  useEffect(() => {
    if (!playSound) {
      console.log("Initializing Soundfont player...");
      playSound(null);
    }
  }, [playSound]);

  const getNotesToPlay = useCallback(() => {
    console.log("Selected Scale:", selectedScale);
    console.log("Selected Position:", selectedPosition);

    if (!fretNotes || fretNotes.length === 0) {
        console.warn("No fret notes available.");
        return [];
    }

    console.log("Fret Notes:", fretNotes);
    console.log("Position Notes:", positionNotes);

    if (selectedScale === 'No Scale') {
        console.warn("No scale selected, returning all notes from the fretboard.");
        return fretNotes.flat();
    }

    if (selectedPosition === 'Show All') {
        const notes = fretNotes.flat().filter(note => positionNotes.includes(note));
        console.log("Notes to Play (Show All):", notes);
        return notes;
    }

    const positionIndex = parseInt(selectedPosition.split(' ')[1], 10) - 1;
    console.log("Calculated Position Index:", positionIndex);

    if (isNaN(positionIndex) || positionIndex < 0 || positionIndex >= fretNotes.length) {
        console.warn(`Position Index ${positionIndex} is out of bounds for fretNotes. FretNotes length: ${fretNotes.length}`);
        return []; // Return an empty array if out of bounds
    }

    const notesForPosition = fretNotes[positionIndex]?.filter(note => positionNotes.includes(note));
    
    if (!notesForPosition || notesForPosition.length === 0) {
        console.warn(`No valid notes found for position index ${positionIndex}.`);
        return [];
    }

    console.log("Notes to Play for Position:", notesForPosition);
    return notesForPosition;
}, [fretNotes, selectedScale, selectedPosition, positionNotes]);

  const notesToPlay = useMemo(() => getNotesToPlay(), [getNotesToPlay]);

  const stepForward = useCallback(() => {
    setCurrentNoteIndex(prevIndex => {
        let nextIndex = prevIndex + 1;

        if (nextIndex >= notesToPlay.length) {
            console.log("Reached the end of notes, checking loop status.");
            if (loop) {
                nextIndex = 0; // Loop back to the start
                console.log("Looping enabled, resetting to start.");
            } else {
                console.log("Stopping playback as loop is not enabled.");
                clearInterval(timerRef.current); // Stop playback if not looping
                setIsPlaying(false);
                return prevIndex;
            }
        }

        const note = notesToPlay[nextIndex];
        if (note) {
            console.log(`Playing note: ${note} at index: ${nextIndex}`);
            playSound(note);
        } else {
            console.warn("No note to play at index:", nextIndex);
        }

        return nextIndex;
    });
}, [notesToPlay, loop, playSound]);
  

  const stepBackward = useCallback(() => {
    setCurrentNoteIndex(prevIndex => {
      let prevIndexUpdated = prevIndex - 1;

      if (prevIndexUpdated < 0) {
        if (loop) {
          prevIndexUpdated = notesToPlay.length - 1; // Loop back to the last note
        } else {
          prevIndexUpdated = 0; // Stay at the first note if not looping
        }
      }

      const note = notesToPlay[prevIndexUpdated];
      if (note) {
        console.log(`Playing sound for note: ${note} at index: ${prevIndexUpdated}`);
        playSound(note);
      } else {
        console.warn("No note to play at index:", prevIndexUpdated);
      }

      return prevIndexUpdated;
    });
  }, [notesToPlay, loop, playSound]);

  useEffect(() => {
    if (isPlaying) {
      if (!bpm || bpm <= 0) {
        console.error('BPM is null or invalid, cannot start playback.');
        setIsPlaying(false);
        return;
      }
      clearInterval(timerRef.current);
      timerRef.current = setInterval(() => {
        stepForward();
      }, (60 / bpm) * 1000); // Set the interval based on bpm
    } else {
      clearInterval(timerRef.current);
    }
    return () => clearInterval(timerRef.current);
  }, [isPlaying, bpm, stepForward]);

  const handlePlayPause = useCallback(() => {
    if (!bpm) {
      console.error('BPM is null or invalid, cannot start playback.');
      return;
    }
  
    if (!isPlaying && currentNoteIndex >= notesToPlay.length - 1) {
      setCurrentNoteIndex(0); // Reset to start from the first note
      if (notesToPlay[0]) playSound(notesToPlay[0]); // Play the first note immediately
    }
  
    setIsPlaying(prevIsPlaying => !prevIsPlaying);
  }, [bpm, isPlaying, currentNoteIndex, notesToPlay, playSound]);

  return {
    isPlaying,
    currentNoteIndex,
    loop,
    handlePlayPause,
    setLoop,
    playSound,
    bpm,
    stepForward,
    stepBackward,
    notesToPlay,
  };
};

export default useAudioControl;
