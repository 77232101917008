import React, { useState, useEffect } from 'react';
import Fretboard from './components/Fretboard';
import logo from './images/guitarscales.png';
import rotateIcon from './images/mobile-rotate.png';
import './App.css';

const initializeGA4 = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', 'G-T0XFCJZNPZ'); // Replace with your GA4 Measurement ID
};

const App = () => {
  const [numFrets, setNumFrets] = useState(22);
  const [selectedSound, setSelectedSound] = useState('acoustic_guitar_nylon');
  const [showNotes, setShowNotes] = useState(true);
  const [showFretNumbers, setShowFretNumbers] = useState(true);
  const [reverseStrings, setReverseStrings] = useState(false);
  const [showScalesPopup, setShowScalesPopup] = useState(false);
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [fretboardNotes, setFretboardNotes] = useState([]); // Notes currently being played on the fretboard
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);

  useEffect(() => {
    // Initialize GA4 on first render
    initializeGA4();

    // Track initial page view
    window.gtag('event', 'page_view', {
      page_path: window.location.pathname + window.location.search,
    });

    // Check screen orientation on load and resize
    function checkOrientation() {
      const isPortrait = window.matchMedia("(orientation: portrait)").matches;
      if (isPortrait) {
        document.querySelector('.rotate-screen').style.display = 'flex';
      } else {
        document.querySelector('.rotate-screen').style.display = 'none';
      }
    }

    // Run checkOrientation on load and when the window is resized
    checkOrientation();
    window.addEventListener('resize', checkOrientation);
    window.addEventListener('orientationchange', checkOrientation);

    // Handle the install prompt for Android devices
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallButton(true); // Show the install button when the event is fired
    });

    // Detect iOS and show the install button for manual installation
    const isIos = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
    const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator.standalone);

    if (isIos && !isInStandaloneMode) {
      setShowInstallButton(true); // Show the install button for iOS
    }

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('resize', checkOrientation);
      window.removeEventListener('orientationchange', checkOrientation);
    };
  }, []);

  const toggleScalesPopup = () => setShowScalesPopup((prevState) => !prevState);
  const toggleSettingsPopup = () => setShowSettingsPopup((prevState) => !prevState);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setShowInstallButton(false);
      });
    } else {
      alert('To add this app to your home screen, tap the "Share" icon and then "Add to Home Screen."');
    }
  };

  // Function to update the fretboardNotes when a note is played
  const handleFretboardNotePlay = (note) => {
    setFretboardNotes([note]); // Update with the note played
  };

  return (
    <div className="App">
      <div className="rotate-screen">
        <img src={logo} alt="Logo" className="rotate-logo" />
        <h1>GuitarScales.Live</h1>
        <h2>Master Your Scales</h2>

        <img src={rotateIcon} alt="Rotate your device" className="rotate-icon rotate-animation" />
        <p className="blinking-text">Rotate Your Device to start</p>

        {showInstallButton && (
          <button className="install-button" onClick={handleInstallClick}>
            Add GuitarScales Live to your home screen
          </button>
        )}

        <div className="try">Full Features try the Desktop App</div>
        <div className="copy">GuitarScales.live © 2024</div>
      </div>

      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
        <div className="text-container">
          <h1>GuitarScales.Live</h1>
          <h2>Master Your Scales</h2>
        </div>
      </div>

      <div className="controls-container">
        <div className="controls-box">
          <Fretboard
            numFrets={numFrets}
            setNumFrets={setNumFrets}
            selectedSound={selectedSound}
            setSelectedSound={setSelectedSound}
            showNotes={showNotes}
            setShowNotes={setShowNotes}
            showFretNumbers={showFretNumbers}
            setShowFretNumbers={setShowFretNumbers}
            reverseStrings={reverseStrings}
            setReverseStrings={setReverseStrings}
            showScalesPopup={showScalesPopup}
            toggleScalesPopup={toggleScalesPopup}
            showSettingsPopup={showSettingsPopup}
            toggleSettingsPopup={toggleSettingsPopup}
            onNotePlay={handleFretboardNotePlay} // Pass the handler to Fretboard
          />
        </div>
      </div>
      <div className="copy">GuitarScales.live © 2024</div>
    </div>
  );
};

export default App;
