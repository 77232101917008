const noteNames = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];

const getScaleIntervals = (scale) => {
  switch (scale) {
    case 'Major':
    case 'Ionian':
      return [2, 2, 1, 2, 2, 2, 1];
    case 'Minor':
    case 'Aeolian':
      return [2, 1, 2, 2, 1, 2, 2];
    case 'Pentatonic Major':
      return [2, 2, 3, 2, 3];
    case 'Pentatonic Minor':
      return [3, 2, 2, 3, 2];
    case 'Blues':
      return [3, 2, 1, 1, 3, 2];
    case 'Chromatic':
      return [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
    case 'Whole Tone':
      return [2, 2, 2, 2, 2, 2];
    case 'Harmonic Minor':
      return [2, 1, 2, 2, 1, 3, 1];
    case 'Melodic Minor':
      return [2, 1, 2, 2, 2, 2, 1];
    case 'Dorian':
      return [2, 1, 2, 2, 2, 1, 2];
    case 'Phrygian':
      return [1, 2, 2, 2, 1, 2, 2];
    case 'Lydian':
      return [2, 2, 2, 1, 2, 2, 1];
    case 'Mixolydian':
      return [2, 2, 1, 2, 2, 1, 2];
    case 'Locrian':
      return [1, 2, 2, 1, 2, 2, 2];
    default:
      return [2, 2, 1, 2, 2, 2, 1];
  }
};

export const getScaleNotes = (scale, key) => {
  const startIndex = noteNames.indexOf(key);
  const scaleIntervals = getScaleIntervals(scale);
  const scaleNotes = [];
  let currentIndex = startIndex;
  for (const interval of scaleIntervals) {
    scaleNotes.push(noteNames[currentIndex % noteNames.length]);
    currentIndex += interval;
  }
  console.log(`Scale Notes for ${scale} in key ${key}:`, scaleNotes);
  return scaleNotes;
};

const getNoteName = (stringIndex, fret, tuning = ['E4', 'B3', 'G3', 'D3', 'A2', 'E2']) => {
  const noteNames = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
  const openStringNote = tuning[stringIndex];
  const noteIndex = (noteNames.indexOf(openStringNote.slice(0, -1)) + fret) % noteNames.length;
  const octave = parseInt(openStringNote.slice(-1), 10) + Math.floor((noteNames.indexOf(openStringNote.slice(0, -1)) + fret) / 12);
  return noteNames[noteIndex] + octave;
};

export const getPositionNotes = (scale, key, position, numFrets = 24) => {
  console.log(`getPositionNotes called with scale: ${scale}, key: ${key}, position: ${position}, numFrets: ${numFrets}`);
  if (scale === 'No Scale') {
    return {
      notes: [], // No notes to show for 'No Scale'
      range: { start: 0, end: numFrets - 1 }
    };
  }
  const scaleNotes = getScaleNotes(scale, key);
  console.log(`getScaleNotes called with scale: ${scale}, key: ${key}`);
  const positions = {
    'Show Full Scale': {
      'Position 1': { start: 0, end: numFrets - 1 },
    },
    'Pentatonic Minor': {
      'Position 1': { start: 12, end: 15 },
      'Position 2': { start: 2, end: 5 },
      'Position 3': { start: 4, end: 7 },
      'Position 4': { start: 5, end: 8 },
      'Position 5': { start: 7, end: 10 },
    },
    'Pentatonic Major': {
      'Position 1': { start: 0, end: 4 },
      'Position 2': { start: 2, end: 6 },
      'Position 3': { start: 4, end: 8 },
      'Position 4': { start: 7, end: 11 },
      'Position 5': { start: 9, end: 13 },
    },
    'Major': {
      'Position 1': { start: 8, end: 10 },
      'Position 2': { start: 7, end: 10 },
      'Position 3': { start: 5, end: 8 },
      'Position 4': { start: 3, end: 7 },
      'Position 5': { start: 2, end: 5 },
      'Position 6': { start: 1, end: 3 },
      'Position 7': { start: 8, end: 12 },
    },
    'Minor': {
      'Position 1': { start: 5, end: 8 },
      'Position 2': { start: 7, end: 10 },
      'Position 3': { start: 8, end: 12 },
      'Position 4': { start: 10, end: 13 },
      'Position 5': { start: 12, end: 15 },
      'Position 6': { start: 13, end: 17 },
      'Position 7': { start: 15, end: 19 },
    },
    'Harmonic Minor': {
      'Position 1': { start: 5, end: 8 },
      'Position 2': { start: 7, end: 10 },
      'Position 3': { start: 8, end: 12 },
      'Position 4': { start: 10, end: 13 },
      'Position 5': { start: 12, end: 15 },
      'Position 6': { start: 13, end: 17 },
      'Position 7': { start: 15, end: 19 },
    },
    'Melodic Minor': {
     'Position 1': { start: 5, end: 8 },
      'Position 2': { start: 7, end: 10 },
      'Position 3': { start: 8, end: 12 },
      'Position 4': { start: 10, end: 13 },
      'Position 5': { start: 12, end: 15 },
      'Position 6': { start: 13, end: 17 },
      'Position 7': { start: 15, end: 19 },
    },
    'Dorian': {
      'Position 1': { start: 10, end: 13 },
      'Position 2': { start: 12, end: 15 },
      'Position 3': { start: 13, end: 17 },
      'Position 4': { start: 15, end: 19 },
      'Position 5': { start: 17, end: 20 },
      'Position 6': { start: 19, end: 22 },
      'Position 7': { start: 21, end: 1 },
    },
    'Phrygian': {
      'Position 1': { start: 12, end: 15 },
      'Position 2': { start: 13, end: 17 },
      'Position 3': { start: 15, end: 19 },
      'Position 4': { start: 17, end: 21 },
      'Position 5': { start: 19, end: 22 },
      'Position 6': { start: 20, end: 1 },
      'Position 7': { start: 22, end: 2 },
    },
    'Lydian': {
      'Position 1': { start: 12, end: 15 },
      'Position 2': { start: 13, end: 17 },
      'Position 3': { start: 15, end: 19 },
      'Position 4': { start: 17, end: 21 },
      'Position 5': { start: 19, end: 22 },
      'Position 6': { start: 20, end: 2 },
      'Position 7': { start: 22, end: 4 },
    },
    'Mixolydian': {
      'Position 1': { start: 0, end: 3 },
      'Position 2': { start: 2, end: 5 },
      'Position 3': { start: 4, end: 7 },
      'Position 4': { start: 5, end: 8 },
      'Position 5': { start: 7, end: 10 },
      'Position 6': { start: 9, end: 12 },
      'Position 7': { start: 11, end: 14 },
    },
    'Aeolian': {
      'Position 1': { start: 0, end: 3 },
      'Position 2': { start: 2, end: 5 },
      'Position 3': { start: 4, end: 7 },
      'Position 4': { start: 5, end: 8 },
      'Position 5': { start: 7, end: 10 },
      'Position 6': { start: 9, end: 12 },
      'Position 7': { start: 11, end: 14 },
    },
    'Locrian': {
      'Position 1': { start: 0, end: 3 },
      'Position 2': { start: 2, end: 5 },
      'Position 3': { start: 4, end: 7 },
      'Position 4': { start: 5, end: 8 },
      'Position 5': { start: 7, end: 10 },
      'Position 6': { start: 9, end: 12 },
      'Position 7': { start: 11, end: 14 },
    },
  };

  if (position === 'Show All') {
    const positionNotes = [];
    for (let stringIndex = 0; stringIndex < 6; stringIndex++) {
      for (let fret = 0; fret <= numFrets; fret++) { // Use <= to include the 24th fret
        const note = getNoteName(stringIndex, fret);
        if (scaleNotes.includes(note.slice(0, -1))) {
          positionNotes.push(note);
        }
      }
    }
    return {
      notes: positionNotes,
      range: { start: 0, end: numFrets } // Range covers the entire fretboard, including the 24th fret
    };
  }

  const selectedPosition = positions[scale] ? positions[scale][position] : undefined;

  if (!selectedPosition) {
    return {
      notes: [], 
      range: { start: 0, end: numFrets - 1 }
    };
  }

  const { start, end } = selectedPosition;

  const positionNotes = [];

  for (let stringIndex = 0; stringIndex < 6; stringIndex++) {
    for (let fret = start; fret <= end; fret++) {
      const note = getNoteName(stringIndex, fret);
      if (scaleNotes.includes(note.slice(0, -1))) {
        positionNotes.push(note);
      }
    }
  }

  console.log(`Position ${position} selected for ${scale}. Notes:`, positionNotes);

  return {
    notes: positionNotes,
    range: { start, end }
  };
};
